




















import Vue from 'vue';
import Header from '@/components/employee/Header.vue';

export default Vue.extend({
  components: {
    Header,
  },
});
